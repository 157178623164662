/**
 * FMPShowcaseAliasCache: Element to share act / agents
 * @license MIT
 * @version 24.1.0
 * Module history
 *   13.1.0 - Initial build
 *   14.4.5 - Extend FMPElement
 *   24.1.0 - Remove extends FMPElement, add FMPDictionary
 * @since January 2022
 * @author Earl Rogers
 */

'use strict';

import {html, LitElement} from 'lit';
import {strings} from './constants.js';

/**
 *  Act / Agent Cache
 */
export class FMPShowcaseAliasCache extends LitElement {
  /*
  static get styles() {
    return unsafeCSS(style);
  }
  */

  static get properties() {
    /* beautify ignore:start */
    return {
      count: {type: Number, reflect: true},
      isLoaded_: {type: Boolean, attribute: 'loaded', reflect: true},
    };
    /* beautify ignore:end */
  }

  static get url() {
    return new URL(strings.SHOWCASE_ALIAS_QUERY);
  }

  constructor() {
    super();
    // eslint-disable-next-line no-undef
    this.dictionary = FMPDictionary;
    this.json_ = null;
    this.map = null;
    this.isLoaded_ = false;
    this.promises_ = [];
    // this.promises_.push(this.isFirstUpdateComplete());
    this.promises_.push(this.isLoaded());
    this.promisesValues_ = null;
  }

  firstUpdated(changedProperties) {
    this.element = this.shadowRoot.querySelector('div[loaded]');
    fetch(this.constructor.url, {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
      },
    })
        .then((response) => response.json())
        .then((data) => {
          this.json_ = data;
          this.map = new Map(Object.entries(this.json_));
          this.isLoaded_ = true;
          this.count = this.map.size;
        });
    super.firstUpdated(changedProperties);
  }

  getHref(showcaseId) {
    if (showcaseId) {
      return this.map.get(showcaseId);
    } else {
      return null;
    }
  }

  isLoaded() {
    return new Promise((resolve, reject) => {
      const this_ = this;
      let attempts = 0;
      (function waitForLoad() {
        if (this_.isLoaded_) return resolve(this_.json_);
        if (attempts < 64) {
          attempts++;
          setTimeout(waitForLoad, 100);
        } else {
          console.error('isLoaded timed out');
          return resolve(null);
        }
      })();
    });
  }

  isReady() {
    return this.promisesValues_ ? this.promisesValues_ :
      Promise.all(this.promises_).then((values) => {
        // values is array of the results of the input promises
        // console.log(values);
        this.promisesValues_ = values;
        return this.promisesValues_;
      });
  }

  get json() {
    return this.json_;
  }

  render() {
    return html`
      <slot></slot>
    `;
  }


  updated(changedProperties) {
  }
}

// Register the new element with the browser.
customElements.define('fmp-showcase-alias-cache', FMPShowcaseAliasCache);
